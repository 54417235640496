<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-plus text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'lg:w-50por'" :show="show" @close="show = false">
            <form @submit.prevent="store">
            <div class="flex flex-col h-full">
                <ModalHeader :type="'new'" :entity="entity" @close="show = false"/>
                <div class="flex flex-col items-center p-4 sm:px-24">
                    <label class="mt-1 sm:mt-4 w-full max-w-md">
                        <span>Nombre</span>
                        <input v-model="form.name" class="form-input mt-1 w-full text-center text-bo-input" placeholder="Nombre" ref="nameRef" required>
                    </label>
                    <label class="mt-1 sm:mt-4 w-full max-w-md">
                        <span>E-mail</span>
                        <input v-model="form.email" type="email" class="form-input mt-1 w-full text-center text-bo-input" placeholder="E-mail" required>
                    </label>
                    <label class="mt-1 sm:mt-4 w-full max-w-md">
                        <span>Contraseña</span>
                        <input type="password" v-model="form.password" class="form-input mt-1 w-full text-center text-bo-input tracking-widest leading-none"
                               placeholder="Contraseña" required>
                    </label>
                    <label class="mt-1 sm:mt-4 w-full max-w-md">
                        <span>Confirmación de contraseña</span>
                        <input type="password" v-model="form.password_confirmation" class="form-input mt-1 w-full text-center text-bo-input tracking-widest leading-none"
                               placeholder="Confirmación de contraseña" required>
                    </label>
                    <div class="mt-1 sm:mt-6 flex flex-col justify-center items-center">
                        <p class="w-full text-center my-4">Permisos de administrador</p>
                        <RadioBinarySelector :parentValue="form.is_admin" :editable="true" @change="isAdminChanged"/>
                    </div>
                </div>
            </div>
            <ModalFooter @close="show = false">
                <Request model="User" action="store" :form="form" @success="onSuccess" @error="onError" ref="storeUserRef"/>
                <ActionBtn :action="'store'" @store="store"/>
            </ModalFooter>
            </form>
        </ModalBase>
    </div>
</template>

<script>
import {Request} from "@/api/components";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";
import RadioBinarySelector from "@/pages/inside/shared/modals/RadioBinarySelector";

export default {
    name: "New",
    components: {
        Request, ModalBase, ModalHeader, ModalFooter, ActionBtn, RadioBinarySelector
    },
    props: [ 'entity' ],
    data() {
        return {
            show: false,
            form: {
                name: null,
                email: null,
                password: null,
                password_confirmation: null,
                is_admin: 0
            }
        }
    },
    methods: {
        store() {
            this.$refs.storeUserRef.request();
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        onSuccess(response) {
            this.response = JSON.parse(JSON.stringify(response));
            this.$emit('refreshDataTable');
            this.$notify(
                { group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
                this.millis
            );
            this.show = false;
        },
        isAdminChanged(value) {
            this.form.is_admin = value !== "NO";
        }
    },
    mounted() {
        this.$refs.nameRef.focus()
    },
}
</script>

<style scoped>

</style>