<template>
    <div class="w-1/2 sm:w-20 rounded rounded-full shadow-md">
        <button v-if="action === 'store'" type="submit"
                class="bo-btn sm:text-sm focus:outline-none bg-main">
            Guardar
        </button>
        <button v-if="action === 'edit'" type="button" @click="emitAction('edit')"
                class="bo-btn sm:text-sm focus:outline-none text-gray-700 bg-bo-yellow">
            Editar
        </button>
        <button v-if="action === 'destroy'" type="button" @click="emitAction('destroy')"
                class="bo-btn sm:text-sm focus:outline-none bg-bo-red">
            Eliminar
        </button>
        <button v-if="action === 'send'" type="button" @click="emitAction('send')"
                class="bo-btn sm:text-sm focus:outline-none bg-indigo-800">
            Enviar
        </button>
    </div>
</template>

<script>
export default {
    name: "ActionBtn",
    props: ['action'],
    methods: {
        emitAction(action) {
            this.$emit(action);
        }
    }
}
</script>

<style scoped>

</style>