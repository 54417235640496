<template>
    <div class="w-48 h-8 cursor-pointer">
        <div class="bg-biselDark rounded-lg p-1 flex w-full h-full">
            <input type="radio" id="SI" value='SI' v-model="mode" class="switch" :disabled="!editable">
            <label @click="setLocalMode('SI')" class="w-24 h-full flex justify-center items-center flex text-biselLight text-xs hover:cursor-pointer">
                <span :class="{'w-full h-full rounded-md bg-box2 shadow-module text-main2 transition flex justify-center items-center': mode }">SI</span>
            </label>
            <input type="radio" id="NO" value='NO' v-model="mode" class="switch" :disabled="!editable">
            <label @click="setLocalMode('NO')" class="w-24 h-full flex justify-center items-center flex text-biselLight text-xs hover:cursor-pointer">
                <span :class="{'w-full h-full rounded-md bg-box2 shadow-module text-main2 transition flex justify-center items-center': !mode }">NO</span>
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "RadioBinarySelector",
    props: ['parentValue', 'editable', 'trueAsDefault'],
    computed: {
        mode() {
            return !!this.parentValue;
        }
    },
    methods: {
        setLocalMode(value) {
            if (!this.editable) {
                this.showModeNotifier();
                return;
            }
            this.$emit('change', value)
        },
    },
}
</script>

<style scoped>
    .switch{
        display: none;
    }
</style>