<template>
  <div v-show="show" class="fixed inset-0 overflow-y-auto z-20 flex bg-gray-900 bg-opacity-75 py-4" @click.self="close">
    <div class="m-auto rounded-md text-left overflow-hidden shadow-modal flex flex-col bg-box text-white"
         :class="width ? width : 'w-full'">
      <slot
          :close="close"
      />
    </div>
  </div>
</template>

<script>
export default {
    name: "ModalBase",
    props: ['width', 'height', 'show'],
    methods: {
      close() {
        this.$emit('close');
      }
    }
}
</script>

<style scoped>

</style>