<template>
    <div class="flex flex-row pl-2 border-b border-gray-900">
        <div v-if="type === 'new'" class="flex flex-col justify-center items-center w-24 h-20">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-16 sm:w-16">
                <i class="mdi mdi-plus text-3xl sm:text-4xl text-yellow-500"></i>
            </div>
        </div>
        <div v-if="type === 'show'" class="flex flex-col justify-center items-center w-24 h-20">
            <div v-if="!editable" class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-16 sm:w-16">
                <i class="mdi mdi-format-list-bulleted text-3xl sm:text-4xl text-blue-500"></i>
            </div>
            <div v-else class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-16 sm:w-16">
                <i class="mdi mdi-pencil text-3xl sm:text:4xl text-yellow-500"></i>
            </div>
        </div>
        <div v-if="type === 'destroy'" class="flex flex-col justify-center items-center w-24 h-20">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-16 sm:w-16">
                <i class="mdi mdi-delete text-3xl sm:text-4xl text-red-400"></i>
            </div>
        </div>
        <div v-if="type === 'send'" class="flex flex-col justify-center items-center w-24 h-20">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-16 sm:w-16">
                <i class="mdi mdi-send text-3xl sm:text-4xl text-gray-400"></i>
            </div>
        </div>
        <div class="flex flex-col justify-center items-center w-full text-lg lg:text-3xl font-medium ml-4">
            <span v-if="type === 'new'">
                <span>Creando</span> {{ entity }}
            </span>
            <span v-if="type === 'show'">
                <span v-if="editable">Modificando</span>
                <span v-else>Mostrando</span>
                {{ entity }}
            </span>
            <span v-if="type === 'destroy'">
                <span>Eliminar</span> {{ entity }}
            </span>
            <span v-if="type === 'send'">
                <span>Enviar</span> {{ entity }}
            </span>
        </div>
        <div class="h-20 w-24">
            <div class="right-0 cursor-pointer text-4xl" @click="close">
                <div class="h-20 p-4 flex justify-center items-center">
                    <i class="mdi mdi-close"></i>
<!--                     <i class="mdi mdi-close ease-in-out transition-all duration-300 hover:rotate-90"></i>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ModalHeader",
    props: ['type', 'editable', 'entity'],
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>