<template>
    <button type="button" @click="close" class="ml-4 w-1/2 inline-flex justify-center rounded-full shadow-md px-6 py-2
        bg-gray-400 font-medium text-gray-700 sm:w-20 sm:text-sm focus:outline-none">
        Cancelar
    </button>
</template>

<script>
export default {
    name: "CancelBtn",
    methods: {
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>