<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-eye text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'lg:w-50por'" :show="show" @close="show = false">
            <form @submit.prevent="store">
            <Request model="User" action="byId" :form="userFilter" @success="onSuccessGetUser" @error="onErrorGetUser" ref="getUserRef"/>
            <div class="flex flex-col h-full">
                <ModalHeader :type="'show'" :editable="editable" :entity="entity" @close="show = false"/>
                <div class="flex flex-col items-center p-4 sm:px-24">
                    <label class="mt-1 sm:mt-4 w-full max-w-md">
                        <span>Nombre</span>
                        <input class="form-input mt-1 w-full text-center text-bo-input" :class="{'bg-gray-200 noselect' : !editable}"
                               placeholder="Nombre" :disabled="!editable" v-model="form.name" ref="name" required>
                    </label>
                    <label class="mt-1 sm:mt-4 w-full max-w-md">
                        <span>E-mail</span>
                        <input class="form-input mt-1 w-full text-center text-bo-input" :class="{'bg-gray-200 noselect' : !editable}"
                               placeholder="E-mail" :disabled="!editable" v-model="form.email" required>
                    </label>
                    <div class="mt-1 sm:mt-6 flex flex-col justify-center items-center">
                        <p class="w-full text-center my-4">Permisos de administrador</p>
                        <RadioBinarySelector :parentValue="form.is_admin" :editable="editable" @change="isAdminChanged"/>
                    </div>
                </div>
            </div>
            <ModalFooter @close="show = false">
                <Request model="User" action="update" :form="form" @success="onSuccess" @error="onError" ref="updateUserRef"/>
                <ActionBtn v-show="!editable" :action="'edit'" @edit="edit"/>
                <ActionBtn v-show="editable" :action="'store'" @store="store"/>
            </ModalFooter>
            </form>
        </ModalBase>
    </div>
</template>

<script>
import {actions} from "@/store";
import {Request} from "@/api/components";
import ModalBase from "@/pages/inside/shared/modals/ModalBase";
import ModalHeader from "@/pages/inside/shared/modals/ModalHeader";
import ActionBtn from "@/pages/inside/shared/modals/ActionBtn";
import ModalFooter from "@/pages/inside/shared/modals/ModalFooter";
import RadioBinarySelector from "@/pages/inside/shared/modals/RadioBinarySelector";

export default {
    name: "Show",
    components: {
        Request, ModalBase, ModalHeader, ModalFooter, ActionBtn, RadioBinarySelector
    },
    props: [ 'entity' ],
    data() {
        return {
            show: false,
            editable: false,
            form: {
                id: null,
                name: null,
                email: null,
                is_admin: null
            },
            userFilter: {
                id: null
            }
        }
    },
    watch: {
        show(n) {
            if (n) {
                this.userFilter.id = this.elSelected.id;
                this.$refs.getUserRef.request();
            } else {
                this.editable = false;
            }
        }
    },
    computed: {
        elSelected() {
            return actions.getElSelected();
        }
    },
    methods: {
        edit(){
            this.editable = !this.editable;
            if (this.editable){
                this.$refs.name.disabled = false;
                this.$refs.name.focus();
            }
        },
        store() {
            this.$refs.updateUserRef.request();
        },
        onError(errors) {
            this.toastErrors(errors)
        },
        onSuccess(response) {
            this.response = JSON.parse(JSON.stringify(response));
            this.$emit('refreshDataTable');
            this.$notify(
                { group: "generic", title: "Info", text: this.response.data.message, type: 'info'},
                this.millis
            );
        },
        onSuccessGetUser(user) {
            this.setForm(user);
        },
        onErrorGetUser(errors) {
            this.toastErrors(errors)
        },
        setForm(user) {
            this.form.id  = user.id;
            this.form.name = user.name;
            this.form.email = user.email;
            this.form.is_admin = user.is_admin;
        },
        isAdminChanged(value) {
            this.form.is_admin = value !== "NO";
        }
    },
}
</script>

<style scoped>

</style>