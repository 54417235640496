<template>
    <div class="flex flex-col justify-end border-t border-gray-900">
        <div class="w-full p-4 flex flex-row justify-end items-center">
            <slot></slot>
            <CancelBtn @close="close"/>
        </div>
    </div>
</template>

<script>
import CancelBtn from "@/pages/inside/shared/modals/CancelBtn";

export default {
  name: "ModalFooter",
  components: {
      CancelBtn
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>